@media only screen and (max-width: 990px) {
  .btn-exit {
    display: inline;
    background-color: #f6f6f6;
    float: right;
    margin-right: -35px;
    z-index: 999999999999999;
  }
}

@media screen and (min-width: 991px) {
  .btn-exit {
    display: none
  }
}

.text-bg-success {
  color: #007237 !important;
  background-color: #CCE3D7 !important;
}

.bg-success {
  color: #fff !important;
  background-color: #007237 !important;
}

.bg-light-success {
  background-color: #E5F1EB;
}

.bg-light2-success {
  background-color: #CCE3D7 !important;
}

.text-bg-danger {
  color: #F45454 !important;
  background-color: #F8E1E1 !important;
}

.cursor-point {
  cursor: pointer !important;
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.v-align-middle {
  vertical-align: middle !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.btn-collapse::before {
  color: #1b6651;
  font-family: 'uicons-solid-rounded';
  content: "\f10d";
  float: left;
  transition: all 0.5s;
}

.btn-collapse.collapsed::before {
  font-family: 'uicons-solid-rounded';
  content: "\f10d";
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

#chart {
  max-width: 380px;
}

.accordion {
  margin-top: 40px;
}

.accordion .card {
  border: none;
  margin-bottom: 20px;
}

.accordion .card .btn-collapse::before {
  color: #1b6651;
  font-family: 'uicons-solid-rounded';
  content: "\f121";
  float: left;
  margin-left: 40%;
  transition: all 0.5s;
}

.accordion .card .btn-collapse.collapsed::before {
  font-family: 'uicons-solid-rounded';
  content: "\f121";
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion .card-body {
  padding-left: 0;
  padding-right: 0;
}

modal-container {
  background-color: #0e0e0e8f;
}
.text-red {
  color: #f45454;
}

.text-green {
  color: #00543C;
}

.text-brown {
  color: #47270e;
}

.rodape {
  position: fixed;
  bottom: 0px;
  width: -webkit-fill-available;
  z-index: 999;
}

/* Define um novo breakpoint xxl para telas maiores que 1400px */
@media (min-width: 1400px) {
  .container-xxl {
    max-width: 1320px;
  }

  .col-xxl {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 { 
    max-width: 8.333333%;
    flex: 0 0 8.333333%;
   }
  .col-xxl-2 { 
    max-width: 16.666667%;
    flex: 0 0 16.666667%; 
  }
  .col-xxl-3 { 
    max-width: 25%;
    flex: 0 0 25%; 
  }
  .col-xxl-4 { 
    max-width: 33.333333%;
    flex: 0 0 33.333333%;  
  }
  .col-xxl-5 { 
    max-width: 41.666667%;
    flex: 0 0 41.666667%;  
  }
  .col-xxl-6 { 
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;  
  }
  .col-xxl-7 { 
    max-width: 58.333333%;
    flex: 0 0 58.333333%; 
  }
  .col-xxl-8 { 
    max-width: 66.666667%;
    flex: 0 0 66.666667%; 
  }
  .col-xxl-9 { 
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%;  
  }
  .col-xxl-10 { 
    max-width: 83.333333%;
    flex: 0 0 83.333333%;  
  }
  .col-xxl-11 { 
    max-width: 91.666667%;
    flex: 0 0 91.666667%; 
  }
  .col-xxl-12 { 
    max-width: 100%;
    flex: 0 0 100%; 
  }
}
@media (min-width: 1400px) {
  /* Margins - mr (margin-right) and ml (margin-left) */
  .mr-xxl-0 { margin-right: 0 !important; }
  .mr-xxl-1 { margin-right: 0.25rem !important; }
  .mr-xxl-2 { margin-right: 0.5rem !important; }
  .mr-xxl-3 { margin-right: 1rem !important; }
  .mr-xxl-4 { margin-right: 1.5rem !important; }
  .mr-xxl-5 { margin-right: 3rem !important; }

  .ml-xxl-0 { margin-left: 0 !important; }
  .ml-xxl-1 { margin-left: 0.25rem !important; }
  .ml-xxl-2 { margin-left: 0.5rem !important; }
  .ml-xxl-3 { margin-left: 1rem !important; }
  .ml-xxl-4 { margin-left: 1.5rem !important; }
  .ml-xxl-5 { margin-left: 3rem !important; }

  /* Padding - pr (padding-right) and pl (padding-left) */
  .pr-xxl-0 { padding-right: 0 !important; }
  .pr-xxl-1 { padding-right: 0.25rem !important; }
  .pr-xxl-2 { padding-right: 0.5rem !important; }
  .pr-xxl-3 { padding-right: 1rem !important; }
  .pr-xxl-4 { padding-right: 1.5rem !important; }
  .pr-xxl-5 { padding-right: 3rem !important; }

  .pl-xxl-0 { padding-left: 0 !important; }
  .pl-xxl-1 { padding-left: 0.25rem !important; }
  .pl-xxl-2 { padding-left: 0.5rem !important; }
  .pl-xxl-3 { padding-left: 1rem !important; }
  .pl-xxl-4 { padding-left: 1.5rem !important; }
  .pl-xxl-5 { padding-left: 3rem !important; }

  /* Margin - Additional margin classes (mt, mb, mx, my) */
  .mt-xxl-0 { margin-top: 0 !important; }
  .mt-xxl-1 { margin-top: 0.25rem !important; }
  .mt-xxl-2 { margin-top: 0.5rem !important; }
  .mt-xxl-3 { margin-top: 1rem !important; }
  .mt-xxl-4 { margin-top: 1.5rem !important; }
  .mt-xxl-5 { margin-top: 3rem !important; }

  .mb-xxl-0 { margin-bottom: 0 !important; }
  .mb-xxl-1 { margin-bottom: 0.25rem !important; }
  .mb-xxl-2 { margin-bottom: 0.5rem !important; }
  .mb-xxl-3 { margin-bottom: 1rem !important; }
  .mb-xxl-4 { margin-bottom: 1.5rem !important; }
  .mb-xxl-5 { margin-bottom: 3rem !important; }

  .mx-xxl-0 { margin-left: 0 !important; margin-right: 0 !important; }
  .mx-xxl-1 { margin-left: 0.25rem !important; margin-right: 0.25rem !important; }
  .mx-xxl-2 { margin-left: 0.5rem !important; margin-right: 0.5rem !important; }
  .mx-xxl-3 { margin-left: 1rem !important; margin-right: 1rem !important; }
  .mx-xxl-4 { margin-left: 1.5rem !important; margin-right: 1.5rem !important; }
  .mx-xxl-5 { margin-left: 3rem !important; margin-right: 3rem !important; }

  .my-xxl-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
  .my-xxl-1 { margin-top: 0.25rem !important; margin-bottom: 0.25rem !important; }
  .my-xxl-2 { margin-top: 0.5rem !important; margin-bottom: 0.5rem !important; }
  .my-xxl-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
  .my-xxl-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
  .my-xxl-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }

  /* Padding - Additional padding classes (pt, pb, px, py) */
  .pt-xxl-0 { padding-top: 0 !important; }
  .pt-xxl-1 { padding-top: 0.25rem !important; }
  .pt-xxl-2 { padding-top: 0.5rem !important; }
  .pt-xxl-3 { padding-top: 1rem !important; }
  .pt-xxl-4 { padding-top: 1.5rem !important; }
  .pt-xxl-5 { padding-top: 3rem !important; }

  .pb-xxl-0 { padding-bottom: 0 !important; }
  .pb-xxl-1 { padding-bottom: 0.25rem !important; }
  .pb-xxl-2 { padding-bottom: 0.5rem !important; }
  .pb-xxl-3 { padding-bottom: 1rem !important; }
  .pb-xxl-4 { padding-bottom: 1.5rem !important; }
  .pb-xxl-5 { padding-bottom: 3rem !important; }

  .px-xxl-0 { padding-left: 0 !important; padding-right: 0 !important; }
  .px-xxl-1 { padding-left: 0.25rem !important; padding-right: 0.25rem !important; }
  .px-xxl-2 { padding-left: 0.5rem !important; padding-right: 0.5rem !important; }
  .px-xxl-3 { padding-left: 1rem !important; padding-right: 1rem !important; }
  .px-xxl-4 { padding-left: 1.5rem !important; padding-right: 1.5rem !important; }
  .px-xxl-5 { padding-left: 3rem !important; padding-right: 3rem !important; }

  .py-xxl-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
  .py-xxl-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
  .py-xxl-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
  .py-xxl-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
  .py-xxl-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
  .py-xxl-5 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
}

/* Classes de tamanho de fonte */
.fs-1 { font-size: 2.5rem !important; }
.fs-2 { font-size: 2rem !important; }
.fs-3 { font-size: 1.75rem !important; }
.fs-4 { font-size: 1.5rem !important; }
.fs-5 { font-size: 1.25rem !important; }
.fs-6 { font-size: 1rem !important; }
.fs-7 { font-size: .70rem !important; }
.fs-8 { font-size: .65rem !important; }
