// Here you can add other styles
body{
    font-family: 'Titillium Web', sans-serif !important;
   background-color: #fff;

}
// $blue:       #005FA0 !default;
// $indigo:     #6610f2 !default;
// $purple:     #6f42c1 !default;
// $pink:       #e83e8c !default;
// $red:        #F45454 !default;
// $orange:     #EF7D00 !default;
// $yellow:     #FFCA00 !default;
// $green:      #1A6550 !default;
// $teal:       #20c997 !default;
// $cyan:       #669FC6 !default;
// $light-blue: #99BFD9 !default;
.text-orange{
    color: #EF7D00;
}
#login {
    background: #C7C7C8 url('../assets/img/brand/header-bg-full.png');
    background-size: 100%;
    background-repeat: no-repeat;

  }

  .breadcrumb {
    background-color: rgba($color: #000000, $alpha: .0);
  }


#login-box{
    position: relative !important;
    top: 10vh;

}

.btn-link {
    color: #007237;
}

a {
    color: #007237;
}

i {
    position: relative;
    top: .2em;
}

.app-header {
    background-color: #F6F6F6;
    border-bottom: #F6F6F6;
}
.btn {
    border-radius: 2em;
}
.btn-date {
  display: inline-block;
  font-weight: 400;
  color: #23282c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-success {
  border-radius: 21px;
    color: #fff;
    background-color: #1A6550;
    border-color: #1A6550;
}

.btn-calendar {
  color: #fff;
  background-color: #1A6550;
  border-color: #1A6550;
}
.card {
    border-radius: .95rem;
    overflow: hidden;
}

.sidebar {
    background: #00543C;
}

.sidebar .nav-link.active {
    color: #fff;
    background: #1A6550;
    border-left: solid 8px #47260E !important;
}



.sidebar .nav-link.active .nav-icon {
    color: #99C7AF;
    position: relative;
    top: 2px;
}

.sidebar .nav-link .nav-icon {
    color: #99C7AF;
}

.sidebar .nav-link:hover {
    color: #fff;
    background: #1A6550 ;
}
.app-header .navbar-toggler-icon {
    height: 23px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='green' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.modal-content {
    border-radius: 21px;
    border-color: #F6F6F6;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  background-color:#1A6550;
  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}



.custom-spinner {
    margin: 15%;
}

.cdk-overlay-container {
    z-index: 9999 !important;
}




