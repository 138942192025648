// Variable overrides
$blue:       #005FA0 !default;
$indigo:     #6610f2 !default;
$purple:     #6f42c1 !default;
$pink:       #e83e8c !default;
$red:        #F45454 !default;
$orange:     #EF7D00 !default;
$yellow:     #FFCA00 !default;
$green:      #1A6550 !default;
$teal:       #20c997 !default;
$cyan:       #669FC6 !default;
$light-blue: #99BFD9 !default;

$primary:       $green !default;
$success:       $green !default;
$info:          $light-blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
