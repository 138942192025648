// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

//data-picker
@import "ng-pick-datetime/assets/style/picker.min.css";
